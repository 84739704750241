import _ from "underscore";
import logger from "core/logger";
import { autoinject } from "aurelia-framework";

import { timesheetStatus } from "helpers/enumHelper";
import dateHelper from "helpers/dateHelper";
import { default as routerHelper } from "helpers/routerHelper";

import { TimesheetProxy } from "./../api/proxies/timesheet-proxy";
import { default as timesheetService } from "services/timesheetService";

interface Group {
    title?: string;
    icon: string;
    showCheckmark: boolean;
    showLinkedEquipment: boolean;
    selected: boolean;
    showIcon: boolean;
    isOpened: boolean;
    linkedEquipment: never[];
    hours: string;
    Items: any[];
}

@autoinject()
export class TimesheetService {

    public LIST_SHOW_CURRENT: string = "ONGOING";
    public LIST_SHOW_PAST: string = "PREVIOUS";
    public LIST_GROUP_TYPE_DATE: string = "DATE";
    public LIST_GROUP_TYPE_EMPLOYEE: string = "EMPLOYEE";

    public PAGE_TYPE_INDEX: string = "PAGE_TYPE_INDEX";
    public PAGE_TYPE_SUMMARY: string = "PAGE_TYPE_SUMMARY";

    constructor(private readonly timesheetProxy: TimesheetProxy) {
    }

    public loadList(loadCurrentEmployeeOnly: boolean, groupType: string, period: string): Promise<any> {
        routerHelper.showLoading();

        // var dfd = this.fetchList(viewmodel, groupType, period);
        // dfd.always(routerHelper.hideLoading);

        // dfd.done((data: any) => {
        //     this.fetchListCallBack(viewmodel, data, groupType, period);
        // });

        // return dfd;

        return this.fetchList(loadCurrentEmployeeOnly, groupType, period)!.then((result: any) => {
            routerHelper.hideLoading();
            return result;
            // return this.fetchListCallBack(viewmodel, result, groupType, period);
        });

    }

    public fetchList(loadCurrentEmployeeOnly: boolean, groupType: string, period: any): Promise<any> | null {
        const previousPayPeriod = this.isPreviousPayPeriod(period);
        if (loadCurrentEmployeeOnly) {
            return timesheetService.getTimesheetsGroupedByType(previousPayPeriod);
        }

        if (groupType === this.LIST_GROUP_TYPE_DATE) {
            return timesheetService.getTimesheetsGroupedByDate(previousPayPeriod);
        }

        if (groupType === this.LIST_GROUP_TYPE_EMPLOYEE) {
            return timesheetService.getTimesheetsGroupedByEmployee(previousPayPeriod);
        }

        logger.error("err_TimesheetUnkownListGroupType");
        return null;
    }

    public mapFirstLevelGroup(viewModel: any, group1: any, groupType: any): any {
        const firstGroup = this.buildBaseGroupItem(viewModel, group1);

        if (groupType === this.LIST_GROUP_TYPE_DATE) {
            firstGroup.title = dateHelper.getDayOfWeekTextDate(group1.Key);
        } else {
            const firstDayOfWeek = _.first(group1.Items);
            this.buildEmployeeGroupItem(viewModel, firstGroup, group1.Key, firstDayOfWeek);
        }

        firstGroup.Items = _.map(group1.Items, (x: any) => this.mapSecondLevelGroup(viewModel, x, groupType));

        return firstGroup;
    }

    public approveTimesheets(timesheetIds: number[]): Promise<void> {
        return this.timesheetProxy.ApproveTimesheets(timesheetIds);
    }

    public completeTimesheet(timeSheetId: number): Promise<void> {
        return this.timesheetProxy.CompleteTimesheet(timeSheetId);
    }

    private mapSecondLevelGroup(viewModel: any, group2: any, groupType: any): Group {
        const secondGroup: Group = this.buildBaseGroupItem(viewModel.type, group2);

        if (groupType === this.LIST_GROUP_TYPE_DATE) {
            this.buildEmployeeGroupItem(viewModel, secondGroup, group2.Key, group2);
        } else {
            secondGroup.title = dateHelper.getDayOfWeekTextDate(group2.Key);
        }

        return secondGroup;
    }

    private isPreviousPayPeriod(period: string): boolean {
        return period !== undefined && period === this.LIST_SHOW_PAST;
    }

    // private fetchListCallBack(viewmodel: any, data: any, groupType: string, period: string): void {
    //     viewmodel.isTeamLeader = data.CurrentEmployeeIsTeamLeader;
    //     viewmodel.teamLeaderIsResponsibleForTimeEntry = data.TeamLeaderIsResponsibleForTimeEntry;
    //     viewmodel.employee = data.CurrentEmployee;
    //     viewmodel.linkedEquipments = data.LinkedEquipments;

    //     if (viewmodel.loadCurrentEmployeeOnly) {
    //         data.list = data.Groups;
    //     } else {
    //         data.list  = _.map(data.Groups, (x: any) => this.mapFirstLevelGroup(viewmodel, x, groupType));
    //     }

    //     viewmodel.cache[period][groupType] = data;
    //     viewmodel.currentData = data;
    // }

    private buildBaseGroupItem(type: string, data: any): Group {
        const group: Group = {
            icon: "",
            showCheckmark: false,
            showLinkedEquipment: false,
            selected: false,
            showIcon: false,
            isOpened: false,
            linkedEquipment: [],
            hours: dateHelper.hoursTohmm(data.TotalHours),
            Items: _.map(data.Items, (x: any, index: number) => {
                x.isLast = (index === data.Items.length - 1);
                x.readonly = type === this.PAGE_TYPE_SUMMARY;
                return x;
            })
        };
        return group;
    }

    private buildEmployeeGroupItem(viewmodel: any, group: Group, employee: any, secondLevelGroup: any): void {
        group.title = employee.FirstName + " " + employee.LastName;

        const firstProj = _.first(secondLevelGroup.Items);
        const firstTimeEntry = _.first(firstProj.Items);
        const status = firstTimeEntry.Status;
        const tsStatus: any = _.find(timesheetStatus, (x: any) => x.id === status)!;

        group.showCheckmark = this.canSelect(viewmodel, firstTimeEntry, tsStatus);
        group.showIcon = this.showIcon(viewmodel, firstTimeEntry, tsStatus);
        group.showLinkedEquipment = !_.isEmpty(employee.LinkedEquipment);
        group.linkedEquipment = employee.LinkedEquipment;

        if (group.showIcon) {
            group.icon = tsStatus ? tsStatus.icon : "";
        }
    }

    private canSelect(viewmodel: any, timeEntry: any, tsStatus: any): boolean {
        if (viewmodel.type !== this.PAGE_TYPE_SUMMARY) {
            return false;
        }

        if (viewmodel.isTeamLeader) {
            if (viewmodel.teamLeaderIsResponsibleForTimeEntry || viewmodel.employee.Id === timeEntry.EmployeeId) {
                //If is responsible for timeentry
                return tsStatus.id === timesheetStatus.INPROGRESS.id ||
                    tsStatus.id === timesheetStatus.COMPLETED.id;
            }

            // Teamleader not responsible
            return tsStatus.id === timesheetStatus.INPROGRESS.id ||
                tsStatus.id === timesheetStatus.COMPLETED.id;
        }

        // Not teamleader
        return tsStatus.id === timesheetStatus.INPROGRESS.id;
    }

    private showIcon(viewmodel: any, timeEntry: any, tsStatus: any): boolean {
        // No icons for this status
        if (!(tsStatus && tsStatus.icon)) {
            return false;
        }

        if (tsStatus.id === timesheetStatus.APPROVED.id) {
            //Always display approved icons
            return true;
        }

        const userIsResponsibleForThisTimeEntry = viewmodel.teamLeaderIsResponsibleForTimeEntry ||
            viewmodel.employee.Id === timeEntry.EmployeeId;

        // Hide icons if current user is responsible for the timesheet
        return !userIsResponsibleForThisTimeEntry;
    }
}
