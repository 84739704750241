import { autoinject, computedFrom } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import enumHelper from "helpers/enumHelper";
import notificationHelper from "helpers/notificationHelper";
import queryStringHelper from "helpers/queryStringHelper";
import routerHelper from "helpers/routerHelper";
import { TimesheetService } from "services/timesheet-service";
import _ from "underscore";
import settingRepository from "repositories/settingRepository";

@autoinject
export class Summary {

    public cache: any = {};
    public currentData: any = {};

    public type: string = this.timesheetService.PAGE_TYPE_SUMMARY;
    public employee: any;
    public isTeamLeader: boolean = false;
    public linkedEquipments: any[] = [];
    public listGroupShown: string = this.timesheetService.LIST_GROUP_TYPE_EMPLOYEE;
    public listPeriodShown: string = this.timesheetService.LIST_SHOW_CURRENT;
    public teamLeaderIsResponsibleForTimeEntry: boolean = true;
    public loadCurrentEmployeeOnly: boolean = false;
    public context: any = this;
    public allSelected: boolean = false;

    @computedFrom("currentData")
    public get list(): any {
        return this.currentData.list;
    }

    @computedFrom("currentData")
    public get timeSheetId(): number {
        return this.currentData.TimeSheetId;
    }

    @computedFrom("currentData")
    public get payPeriodStartDate(): any {
        return this.currentData.PayPeriodStartDate;
    }

    @computedFrom("currentData")
    public get timeSheetStatus(): string {
        return this.currentData.TimeSheetStatus;
    }

    @computedFrom("currentData")
    public get payPeriodIsReadonly(): boolean {
        return this.currentData.PayPeriodIsReadonly;
    }

    @computedFrom("currentData")
    public get showComplete(): boolean {
        return !this.isTeamLeader && !this.teamLeaderIsResponsibleForTimeEntry;
    }

    @computedFrom("currentData")
    public get disableComplete(): boolean {
        return this.timeSheetStatus !== enumHelper.timesheetStatus.INPROGRESS.id.toString();
    }

    @computedFrom("currentData")
    public get showApprove(): boolean {
        return this.isTeamLeader &&
            _.some(this.list,
                (groupEmployee: any) => {
                    return groupEmployee.showCheckmark;
                });
    }

    constructor(public readonly i18n: I18N, public readonly timesheetService: TimesheetService) {
    }

    public attached(): any {
        this.allSelected = _.every(this.list, (x: any) => x.selected);
    }

    public activate(querystring: string): any {
        this.bindViewModel(querystring);
        this.clearCache();
        return this.loadData();
    }

    public showPeriod(period: string): void {
        this.listPeriodShown = period;
        this.loadData();
    }

    public showGroup(group: string): void {
        this.listGroupShown = group;
        this.loadData();
    }

    public toggleOpenSection(data: any): void {
        data.isOpened = !data.isOpened;
    }

    public selectEntry(data: any): void {
        if (event) {
            event.stopPropagation();
        }

        if (!data.selected && data.icon === "fa-hourglass-half") {
            notificationHelper.showDialogYesNo(this.i18n.tr("TimeSheetApprovalInProgressStatusTimeSheet")).then((response: boolean) => {
                if (response) {
                    data.selected = true;
                }
            });
        } else {
            data.selected = !data.selected;
        }

        this.allSelected = _.every(this.list, (x: any) => x.selected || x.icon === "fa-hourglass-half");
    }

    public selectAll(): void {
        _.each(this.list,
            (x: any) => {
                x.selected = !this.allSelected && x.icon !== "fa-hourglass-half" ;
            });

        this.allSelected = _.every(this.list, (x: any) => x.selected || x.icon === "fa-hourglass-half");
    }

    public approve(): void {
        const timesheetIds: number[] = this.getSelectedTimesheetsIds();

        if (_.isEmpty(timesheetIds)) {
            notificationHelper.showWarning(this.i18n.tr("err_SelectAtLeastOneTimesheet"));
            return;
        }

        notificationHelper.showDialogYesNo(this.i18n.tr("SignatureConfirmationSummaryLeaderTimesheet")).then((data: boolean) => {
            if (data) {
                this.doApprove(timesheetIds);
            }
        });
    }

    public complete(): void {
        notificationHelper.showDialogYesNo(this.i18n.tr("SignatureConfirmationSummaryEmployeeTimesheet")).then((data: boolean) => {
            if (data) {
                this.doComplete();
            }
        });
    }

    public genUrl(): string {
        return "#";
    }

    private doApprove(timesheetIds: number[]): void {
        routerHelper.showLoading();

        this.timesheetService.approveTimesheets(timesheetIds)
            .then(() => {
                settingRepository.clearTimesheetDefaultValues();
                routerHelper.hideLoading();
                routerHelper.navigateBack();
            });
    }

    private doComplete(): void {
        routerHelper.showLoading();

        this.timesheetService.completeTimesheet(this.timeSheetId)
            .then(() => {
                routerHelper.hideLoading();
                routerHelper.navigateBack();
            });
    }

    private getSelectedTimesheetsIds(): any[] {
        const timesheetIds: any[] = [];

        _.each(this.list, (grpEmployee: any) => {
                if (grpEmployee.selected) {
                    const grpDay = _.first(grpEmployee.Items);
                    const grpProj = _.first(grpDay.Items);
                    const timesheet = _.first(grpProj.Items);
                    timesheetIds.push(timesheet.TimesheetId);
                }
        });

        return timesheetIds;
    }

    private async loadData(): Promise<any> {
        const period: string = this.listPeriodShown;
        const groupType: string = this.listGroupShown;

        if (period && groupType && this.cache[period][groupType]) {
            this.currentData = this.cache[period][groupType];
        }

        await this.timesheetService.loadList(this.loadCurrentEmployeeOnly, groupType, period).then((result: any) => {
            this.isTeamLeader = result.CurrentEmployeeIsTeamLeader;
            this.teamLeaderIsResponsibleForTimeEntry = result.TeamLeaderIsResponsibleForTimeEntry;
            this.employee = result.CurrentEmployee;
            this.linkedEquipments = result.LinkedEquipments;

            if (this.loadCurrentEmployeeOnly) {
                result.list = result.Groups;
            } else {
                result.list  = _.map(result.Groups, (x: any) => this.timesheetService.mapFirstLevelGroup(this, x, groupType));
            }

            this.cache[period][groupType] = result;
            this.currentData = result;
        });
    }

     private bindViewModel(querystring: string): any {
        this.loadCurrentEmployeeOnly = !queryStringHelper.parseIsTeamLeaderTimesheet(querystring);
        this.listPeriodShown = routerHelper.getQuerystring(querystring).ListPeriodShown;
    }

    private clearCache(): void {
        this.cache = {};
        this.cache[this.timesheetService.LIST_SHOW_CURRENT] = {};
        this.cache[this.timesheetService.LIST_SHOW_PAST] = {};

        this.currentData = { list: [] };
    }
}
