// GENERATED FILE - DO NOT MODIFY
export enum TransactionType {
    PurchaseOrder = "P",
    InventoryProjectTransfer = "4",
    InventorySiteTransfer = "A",
    InventoryAdjustment = "3",
    MaterialReservation = "H",
    InternalSales = "B",
}

export let nameof = "TransactionType";
