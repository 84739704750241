import { I18N } from "aurelia-i18n";
import { autoinject, bindable, observable } from "aurelia-framework";
import notificationHelper from "helpers/notificationHelper";
import { default as EmailHelper } from "helpers/emailHelper";

@autoinject
export class MaSignatureAndPad {

    @bindable public id: any;
    @bindable public getList!: () => { transport: any, mapResults: any};
    @bindable public onSignatureCompleted!: () => any;

    @bindable public plainTextSignature: string = "";
    @bindable public email: string = "";
    @bindable public title: string = "";

    @bindable public showPlainTextSignature: boolean = true;
    @bindable public showEmailSection: boolean = true;

    @bindable public plainTextSignatureMaxLength: number = 999;

    @bindable public signatureId?: number = 0;
    @bindable public disabled: boolean = false;
    @bindable public required: boolean = false;
    @bindable public emailRequired: boolean = false;
    @bindable public specifiedLanguage: string = "";

    @bindable @observable public selectedContact: any;
   
    public displayRequired: string = "";
    public isSignaturePadOpened: boolean = false;
    public notificationHelper: typeof notificationHelper = notificationHelper;

    constructor(private readonly i18n: I18N, private readonly emailHelper: EmailHelper) {}

    public bind(): void {
        this.displayRequired = this.required ? `(${this.i18n.tr("Required")})` : "";
    }

    public selectedContactChanged(newValue: any): void {
        if (!newValue) {
            this.plainTextSignature = "";
            this.email = "";
            return;
        }
        this.plainTextSignature = newValue.text;
        this.email = newValue.data.data;

    }

    public openSignaturePad(): void {
        if (this.plainTextSignature === "") {
            this.notificationHelper.showWarning(this.i18n.tr("err_SignatureRequired"));
            return;
        }

        if (this.email.length > 0) {
            let message = this.i18n.tr("err_InvalidEmail");

            const invalidEmails = this.emailHelper.findInvalidEmailsInString(this.email);
            if (invalidEmails.length >= 1) {
                message += " " + invalidEmails.join(", ");
                this.notificationHelper.showWarning(message);
                return;
            }
        }

        if (this.isSafari() && window.innerWidth < window.innerHeight) {
            alert(this.i18n.tr("RotateToActivateText"));
        }

        this.isSignaturePadOpened = true;
    }

    private isSafari(): boolean {
        return window.navigator.userAgent.toLowerCase().indexOf("safari") > -1 && window.navigator.userAgent.toLowerCase().indexOf("chrome") <= -1;
    }
}
