import { ScrollHelper } from "helpers/scroll-helper";
import { autoinject, bindable } from "aurelia-framework";
import userSettingService from "services/userSettingsService";

@autoinject
export class CollapseAll {
  public wrapper: any = null;

  @bindable
  public isSticky: boolean = false;
  @bindable
  public byCategory: boolean = false;

  constructor(private readonly scrollHelper: ScrollHelper) { }

  public bind(): void {
    if (this.isSticky) {
      this.scrollHelper.fixTop(this.wrapper);
    }
  }

  public async collapseAll(): Promise<void> {
      await this.collapseLogic(true, this.byCategory ? ".sub-drawer.open" : ".drawer.open");
  }

  public async expandAll(): Promise<void> {
      await this.collapseLogic(false, this.byCategory ? ".sub-drawer:not(open)" : ".drawer:not(open)");
  }

  private async collapseLogic(collapse: boolean, selector: string): Promise<void> {
    const drawers = document.querySelectorAll(selector);

    for (let i = 0; !!drawers[i]; i++) {
        const drawer = drawers[i];

        if (collapse) {
            drawer.classList.remove("open");
        } else {
            drawer.classList.add("open");
        }
    }
    if (this.byCategory) {
        await userSettingService.setProfileEmployeeDocumentByCategoryDrawerOpenForCurrentUserSetting(!collapse);
    }
  }
}
