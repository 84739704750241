import queryStringHelper from "helpers/queryStringHelper";
import materialRequisitionService from "services/materialRequisitionService";
import { DispatchProjectService } from "services/dispatch-project-service";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class ProjectMaterialRequisitionEdit extends FormBase {
    public actions: any = {};
    public dispatchProjectCode: string = "";
    public requisitionId: string = "";
    public isProjectResponsible: boolean = false;
    public isDirty: boolean = false;
    public parentModel: any = null;
    public readonly: boolean = false;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly dispatchProjectService: DispatchProjectService) {
        super(notificationHelper, i18n, router);
    }

    public checkDirty(): boolean {
        return this.isDirty;
    }

    public async canActivate(params: any): Promise<any> {
        this.dispatchProjectCode = params.dispatchProjectCode;

        try {
            await this.checkIsProjectActive();
        } catch (e) {
            if (e && e.response && e.response.data && e.response.data.Exception && e.response.data.Exception.ErrorId) {
                this.notificationHelper.showError(this.i18n.tr(`ApiError.${e.response.data.Exception.ErrorId}`), undefined, { timeOut: 0, extendedTimeOut: 0 });
            } else {
                this.notificationHelper.showError(e.response.data.Exception.Message, undefined, { timeOut: 0, extendedTimeOut: 0 });
            }
            return false;
        }
        return true;
    }

    public activate(params: any): any {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.requisitionId = params.requisitionId;
        this.isProjectResponsible = queryStringHelper.parseIsProjectResponsible(params.q);
        this.parentModel = this;
        this.readonly = queryStringHelper.parseReadonly(params.q);
    }

    public bind(): any {
        this.actions = {
            getMaterialRequisition: materialRequisitionService.getMaterialRequisition.bind(materialRequisitionService, this.requisitionId),
            saveMaterialRequisitionService: materialRequisitionService.setMaterialRequisitionHeaderForProject.bind(materialRequisitionService, this.dispatchProjectCode),
            setSpecificFields: this.setSpecificFields
        };

    }

    public setSpecificFields(item: any): any {
        item.dispatchProjectCode = this.dispatchProjectCode;
        return item;
    }

    private async checkIsProjectActive(): Promise<void> {
        await this.dispatchProjectService.GetProjectFromDispatchProjectCode(this.dispatchProjectCode);
    }
}
