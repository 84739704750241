import { inject } from "aurelia-framework";
import { Redirect } from "aurelia-router";
import { I18N } from "aurelia-i18n";

import { default as apiHelper } from "helpers/apiHelper";
import { default as dateHelper } from "helpers/dateHelper";
import { default as settingHelper } from "helpers/settingHelper";
import { default as queryStringHelper } from "helpers/queryStringHelper";
import { default as notificationHelper } from "helpers/notificationHelper";

import { default as templateService } from "services/templateService";
import { default as materialRequisitionService } from "services/materialRequisitionService";
import { default as projectService } from "services/projectService";

import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@inject(I18N, NotificationHelper, Router)
export default class MaterialRequisitionItem extends FormBase {
    actions = {};
    dispatchProjectCode;
    lineNo = '';
    requisitionDate = null;
    requisitionId = '';
    showNotInCatalog = false;
    readonly = false;

    constructor(i18n, notificationHelper, router) {
        super(notificationHelper, i18n, router);
    }

    checkDirty() {
        if (this.readonly) {
            return false;
        }

        return this.isDirty;
    }

    bindViewModel(dispatchProjectCode, requisitionId, requisitionDate, lineNo, queryString) {
        this.dispatchProjectCode = dispatchProjectCode;
        this.requisitionDate = requisitionDate;
        this.requisitionId = requisitionId;
        this.lineNo = lineNo;

        this.catalogBaseUrl = apiHelper.getBaseUrl() +
            "/catalog/dispatch/projects/materialrequisitions?dispatchTemplateId=" +
            settingHelper.getSelectedDispatchModel();
    }

    setSpecificFields(item) {
        item.dispatchProjectCode = this.dispatchProjectCode;
        return item;
    }

    bindWidget() {
        this.actions = {
            setSpecificFields: item => this.setSpecificFields(item),
            saveMaterialRequisitionItem: (requisitionId, lineNo, item) => materialRequisitionService.setMaterialRequisitionItemForProject(this.dispatchProjectCode, requisitionId, lineNo, item),
            getActivities: () => projectService.getActivitiesForDispatchProject(this.dispatchProjectCode)
        };
    }

    canActivate() {
        if (!settingHelper.hasDispatchModel()) {
            notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"));
            return new Redirect("Settings");
        }
        return true;
    }

    activate(params) {
        const dispatchProjectCode = params.dispatchProjectCode;
        const requisitionId = params.requisitionId;
        const requisitionDate = dateHelper.dateFromNavigationParameter(params.date);
        const lineNo = params.lineNo;
        const queryString = params.q;

        this.bindViewModel(dispatchProjectCode, requisitionId, requisitionDate, lineNo, queryString);
        this.bindWidget();

        if (params.q) {
            this.readonly = queryStringHelper.parseReadonly(params.q);
        }

        return templateService.getUserTemplateConfigs().done((configs) => {
            this.showNotInCatalog = configs.ShowNotInCatalogMaterialProject;
        });
    }
}