import { DialogCloseResult } from "aurelia-dialog";
import { ClientStatus } from "api/enums/client-status";
import { ClientLookupModel } from "api/models/company/client/client-lookup-model";
import { ProjectModel } from "api/models/company/project/project-model";
import { FieldServicesWorkOrderDetailsModel } from "api/models/company/workorder/field-services-work-order-details-model";
import { FieldServicesWorkOrderLookupModel } from "api/models/company/workorder/field-services-work-order-lookup-model";
import { FieldServicesWorkOrderWriteModel } from "api/models/company/workorder/field-services-work-order-write-model";
import { WorkOrderProgressStatusModel } from "api/models/company/workorder/work-order-progress-status-model";
import { WorkOrderTypeModel } from "api/models/company/workorder/work-order-type-model";
import { autoinject, PLATFORM } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import dateHelper from "helpers/dateHelper";
import enumHelper from "helpers/enumHelper";
import queryStringHelper from "helpers/queryStringHelper";
import settingHelper from "helpers/settingHelper";
import moment from "moment";
import clientService from "services/clientService";
import projectService from "services/projectService";
import templateService from "services/templateService";
import workOrderService from "services/workOrderService";
import { Redirect } from "aurelia-router";
import routerHelper from "helpers/routerHelper";
import val from "core/val";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";

@autoinject
export class FieldServiceDetailEdit extends FormBase {

    public readonly: any;
    public editMode: string = "";

    public wordOrderId: string = "";

    public toggleModal: boolean = false;

    public dispatchTemplateCode: string = "";

    public customer: any;
    public customerReadonly: boolean = false;
    public disableSave: boolean = false;

    public id: string | null = null;
    public comment: string = "";
    public description: string = "";
    public date: Date = dateHelper.getNow();
    public workOrderDate: Date = dateHelper.getNow();
    public closingDate: Date | null = null;
    public poNumber: string = "";
    public project: any;
    public reference: string = "";
    public type: any;

    public startHour: string | null = null;
    public endHour: string | null = null;

    public originalWO: any;
    public sourceWorkorder: any;
    public defaultWorkOrderType: string | null =  null;

    public progressState: any;
    public noHours: boolean = false;

    public selectedChoice: number = 0;

    public val: typeof val;
    public routerHelper: typeof routerHelper;

    public unModified: FieldServicesWorkOrderWriteModel | null = null;

    private errors: string[] = [];
    private constEmptyItem: string = "[empty]";

    private readonly dialogTemplate: any = PLATFORM.moduleName("pages/templates/dialogTemplates/startEndTimeTemplate");

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router) {
        super(notificationHelper, i18n, router);
        this.routerHelper = routerHelper;
        this.val = val;
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        if (!this.unModified) {
            return false;
        }

        const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");
        const stringifyCurrent = JSON.stringify(this.buildSetData(false)).replace(/[^0-9A-Z]+/gi, "");

        return stringifyUnmodified !== stringifyCurrent;
    }

    public canActivate(): boolean | Redirect {
        if (!settingHelper.hasDispatchModel()) {
            this.notificationHelper.showWarning(this.i18n.tr("DispatchModelRequired"), "");
            return new Redirect("Settings");
        }

        return true;
    }

    public bind(): any {
        this.toggleModal = !this.id;
    }

    public async activate(params: any): Promise<any> {
        this.clear();
        this.dispatchTemplateCode = settingHelper.getSelectedDispatchModel();
        this.readonly = queryStringHelper.parseReadonly(params.q);
        this.editMode = queryStringHelper.parse(params.q, "editMode");
        this.wordOrderId = decodeURIComponent(params.workOrderId);

        const result = await this.loadData(this.wordOrderId);
        this.bindViewModel(result);

        this.setDisplayProperties();

        this.unModified = this.buildSetData(!this.editMode);
    }

    public clear(): void {
      this.clearForm();

      this.readonly = false;
      this.disableSave = false;
      this.customerReadonly = false;
      this.sourceWorkorder = null;
    }

    public get loadClients(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                clientService.getClientsLookup(params.data.filter, params.data.page || 1).then(
                    (result: ClientLookupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ClientLookupModel): any => {
                return { id: item.Id, text: item.Description };
            }
        };
    }

    public get loadSourceWorkorders(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                workOrderService.listLast5Workorder().then(
                    (result: FieldServicesWorkOrderLookupModel[]) => {
                        result.unshift({ Id: this.constEmptyItem, Description: this.i18n.tr("New") });
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: FieldServicesWorkOrderLookupModel): any => {
                if (item.Id === this.constEmptyItem) {
                    return { id: item.Id, text: item.Description, data: item };
                }
                return { id: item.Id, text: item.Id + " - " + item.Description, data: item };
            }
        };
    }

    public get loadProjects(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                projectService.listLookup(this.dispatchTemplateCode, params.data.filter, params.data.page || 1).then(
                    (result: ProjectModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ProjectModel): any => {
                return { id: item.Id, text: item.Id + " - " + item.Description, data: item };
            }
        };
    }

    public get loadTypes(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                workOrderService.listTypes(params.data.filter, params.data.page || 1).then(
                    (result: WorkOrderTypeModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: WorkOrderTypeModel): any => {
                return { id: item.Id, text: item.Id + " - " + item.Description };
            }
        };
    }

    public get loadWorkOrders(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                workOrderService.listOriginalLookup(params.data.filter, params.data.page || 1).then(
                    (result: FieldServicesWorkOrderLookupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: FieldServicesWorkOrderLookupModel): any => {
                return { id: item.Id, text: item.Id + " - " + item.Description };
            }
        };

    }

    public get loadProgressStates(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                workOrderService.listProgressStateLookup().then(
                    (result: WorkOrderProgressStatusModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: WorkOrderProgressStatusModel): any => {
                return { id: item.Id, text: item.Id + " - " + item.Description };
            }
        };
    }

    public bindQueryString(querystring: string): void {
        this.readonly = queryStringHelper.parseReadonly(querystring);
        this.editMode = queryStringHelper.parse(querystring, "editMode");
    }

    public setDisplayProperties(): void {
        this.customerReadonly = !!this.customer;
    }

    public async updateCustomerForProject(item: any): Promise<void> {
        this.disableSave = false;
        if (item) {
            const project: ProjectModel = item.data;
            const client = await clientService.getClientFromProject(project.Id!);
            if (client) {
                this.checkBlockedClient(client);

                if (this.disableSave) { return; }
            }
        }

        if (item && item.data.CustomerCode) {
            const project: ProjectModel = item.data;

            this.customer = { id: project.CustomerCode, text: project.CustomerCode + " - " + project.Customer };
            this.customerReadonly = true;
        } else {
            this.customerReadonly = false;
            this.customer = null;
        }
    }

    public createWorkorderFromCopy(item: any): void {
        if (item && item.id !== this.constEmptyItem) {
            this.askForAttendanceAndTimesheets(item);
        } else {
            this.initialiseStartEndHours();
        }
    }

    public save(): void {
        if (this.isFormValid()) {
            this.routerHelper.showLoading();
            const data = this.buildSetData(false);

            workOrderService.set(this.dispatchTemplateCode, data).then((result: any) => {
                this.unModified = null;
                this.goToId(result);
                this.routerHelper.hideLoading();
            });
        } else {
            this.notificationHelper.showValidationError(this.errors);
        }

    }

    private goToId(id: string): void {
        const url = routerHelper.navigateTo("FieldService_Detail") + "/" + id;
        this.routerHelper.navigate(url, { replace: true, trigger: true });
    }

    private async createCopy(workorderId: string, copyAttendance: boolean, copyTimesheets: boolean, copyMaterials: boolean, startHour?: any, endHour?: any): Promise<void> {
        await workOrderService.createCopy(workorderId, copyAttendance, copyTimesheets, copyMaterials, startHour, endHour).then((result: any) => {
            this.unModified = null;
            this.goToId(result);
        });
    }

    private async askForAttendanceAndTimesheets(item: any): Promise<void> {
        await this.notificationHelper.showOptionsDialog(
            this.i18n.tr("msg_ChoicePrecisionToCopy"),
            "",
            {
                selectedValue: this.selectedChoice,
                optionsList: [
                    {
                        value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.id,
                        text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESONLY.label
                    },
                    {
                        value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.id,
                        text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.label
                    },
                    {
                        value: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.id,
                        text: enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.label
                    }
                ]
            }).then(async (success: DialogCloseResult) => {
                if (!success.wasCancelled) {
                    this.selectedChoice = success.output.selectedValue;
                    this.enterNewHours(item.id);
                } else {
                    this.unModified = null;
                    this.routerHelper.navigateBack();
                }
            });
    }

    private async showStartEndHoursPopup(workOrderId: string): Promise<any> {
        this.initialiseStartEndHours();
        const dialogOptions = {
            templateContext: {
                startHour: dateHelper.addTimeSpan(new Date(), this.startHour),
                endHour: dateHelper.addTimeSpan(new Date(), this.endHour)
            }
        };
        await this.notificationHelper.showTemplateDialog("", "", this.dialogTemplate, dialogOptions).then(async (success: DialogCloseResult) => {
            if (!success.wasCancelled) {
                //create copy using selected Start/End hours from dialog
                const isChoiceRessourcesAndTimeAndMaterials = this.selectedChoice === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.id;
                const isChoiceRessourcesAndTime = this.selectedChoice === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.id;
                this.createCopy(workOrderId, true, isChoiceRessourcesAndTime || isChoiceRessourcesAndTimeAndMaterials, isChoiceRessourcesAndTimeAndMaterials, dateHelper.getTime(success.output.startHour), dateHelper.getTime(success.output.endHour));
            } else {
                this.unModified = null;
                this.routerHelper.navigateBack();
            }
        });
    }

    private enterNewHours(workOrderId: string): void {
        this.routerHelper.showLoading();
        workOrderService.isOldworkOrder(workOrderId).done((isOld: boolean) => {
            if (isOld) {
                this.showStartEndHoursPopup(workOrderId);
            } else {
                const isChoiceRessourcesAndTimeAndMaterials = this.selectedChoice === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIMEANDMATERIALS.id;
                const isChoiceRessourcesAndTime = this.selectedChoice === enumHelper.copyDailyEntryOrWorkOrderChoices().RESSOURCESANDTIME.id;
                //createCopyDirectly
                this.createCopy(workOrderId, true, isChoiceRessourcesAndTime || isChoiceRessourcesAndTimeAndMaterials, isChoiceRessourcesAndTimeAndMaterials);
            }
        }).always(this.routerHelper.hideLoading);
    }

    private initialiseStartEndHours(): void {
        const templateConfigs = templateService.getCurrentTemplateConfigs();
        const templateStarHour: string = templateConfigs.WorkingStartHour;
        const templateEndHour: string = templateConfigs.WorkingEndHour;
        this.startHour = templateStarHour.slice(0, 5);
        this.endHour = templateEndHour.slice(0, 5);
    }

    private checkBlockedClient(client: any): void {
        if (client.Status === ClientStatus.Blocked) {
            this.disableSave = true;
            const message = this.i18n.tr("msg_BlockedCustomer").replace("{0}", client.Id).replace("{1}", client.Description).replace("{2}", client.StatusReason);
            this.notificationHelper.showWarning(message, "", { timeOut: 0 });

        } else if (client.Status === ClientStatus.Warning) {
            const warning = this.i18n.tr("msg_BlockedCustomerWarning").replace("{0}", client.Id).replace("{1}", client.Description).replace("{2}", client.StatusReason);
            this.notificationHelper.showWarning(warning, "", { timeOut: 0 });
        }
    }

    private async setDefaultWorkOrderType(): Promise<void> {
        const template = await templateService.getTemplateConfigs(this.dispatchTemplateCode);
        //console.warn(template.DefaultWorkOrderType);

        if (template.DefaultWorkOrderType) {
            const listWoTypes = await workOrderService.listTypes();

            if (listWoTypes.length > 0) {
                const selectedWorkOrderType = listWoTypes.find((item: any) => item.Id === template.DefaultWorkOrderType);
                if (selectedWorkOrderType) {
                    this.defaultWorkOrderType = selectedWorkOrderType.Id;
                    this.type = {
                        id: selectedWorkOrderType.Id,
                        text: selectedWorkOrderType.Id + " - " + selectedWorkOrderType.Description
                    };
                }
            }
        }
    }

    private buildSetData(init: boolean = true): FieldServicesWorkOrderWriteModel {
        return {
            Id: this.id ? this.id : "0",
            Description: this.description,
            Date: this.date!,
            Reference: this.reference,
            OriginalWorkOrderId: this.originalWO ? this.originalWO.id : "",
            ProjectCode: this.project ? this.project.id : "",
            CustomerCode: this.customer ? this.customer.id : "",
            PoNumber: this.poNumber,
            WorkOrderTypeId: this.type ? this.type.id : init ? this.defaultWorkOrderType : "",
            ProgressStatusId: this.progressState ? this.progressState.id : "",
            Comment: this.comment,
            StartTime: this.startHour!,
            EndTime: this.endHour!,
            WorkOrderDate: this.workOrderDate!,
            ClosingDate: this.closingDate
        };
    }

    private isFormValid(): boolean {
        this.errors = [];

        if (!this.date) {
            this.errors.push(this.i18n.tr("err_DateRequired"));
        }
        if (!this.workOrderDate) {
            this.errors.push(this.i18n.tr("err_DateRequired"));
        }
        if (this.closingDate && this.date && this.closingDate < this.date) {
            this.errors.push(this.i18n.tr("err_ClosingDateMustBeGreaterThanStartingDate"));
        }
        if (this.closingDate && this.date && dateHelper.parseTimeToDecimal(this.startHour!) > dateHelper.parseTimeToDecimal(this.endHour!)  && this.closingDate < this.date) {
            this.errors.push(this.i18n.tr("err_ClosingDateMustBeGreaterThanStartingDateStaringHoursIsGreaterThanEndingHours"));
        }
        if (!this.description) {
            this.errors.push(this.i18n.tr("err_DescriptionRequired"));
        }
        if (!this.project) {
            this.errors.push(this.i18n.tr("err_ProjectRequired"));
        }

        return this.errors.length === 0;
    }

    private async loadData(workOrderId: string): Promise<FieldServicesWorkOrderDetailsModel | null> {
        if (this.editMode === enumHelper.fieldServiceEditMode().EDIT) {

            const data = await workOrderService.get(workOrderId);
            this.bindViewModel(data);

            return data;
        } else {
            await this.setDefaultWorkOrderType();
            return null;
        }
    }

    private bindViewModel(data: FieldServicesWorkOrderDetailsModel | null): void {
        if (!data) {
            return;
        }

        this.comment = data.Comment ? data.Comment : "";
        this.description = data.Description ? data.Description : "";

        if (data.CustomerCode) {
            this.customer = { id: data.CustomerCode, text: data.CustomerCode + " - " + data.CustomerName };
        }

        if (this.editMode === enumHelper.fieldServiceEditMode().EDIT) {
            this.date = moment(data.Date).toDate();
            this.workOrderDate = moment(data.WorkOrderDate).toDate();
            if (data.ClosingDate) {
                this.closingDate = moment(data.ClosingDate).toDate();
            }
        } else {
            this.date = moment().toDate();
            this.workOrderDate = moment().toDate();
        }

        if (this.editMode === enumHelper.fieldServiceEditMode().SUB) {
            this.originalWO = { id: data.Id, text: data.Id + " - " + data.Description };
        } else if (data.OriginalWorkOrderId) {
            this.originalWO = {
                id: data.OriginalWorkOrderId,
                text: data.OriginalWorkOrderId + " - " + data.OriginalWorkOrderDescription
            };
        }

        this.id = this.editMode === enumHelper.fieldServiceEditMode().EDIT ? data.Id : null;
        this.poNumber = data.PoNumber ? data.PoNumber : "";

        if (data.ProgressStatusId) {
            this.progressState = {
                id: data.ProgressStatusId,
                text: data.ProgressStatusId + " - " + data.ProgressStatusDescription
            };
        }

        if (data.ProjectCode) {
            this.project = {
                id: data.ProjectCode,
                text: data.ProjectCode + " - " + data.ProjectDescription
            };
        }

        this.reference = data.Reference ? data.Reference : "";

        if (data.WorkOrderTypeId) {
            this.type = {
                id: data.WorkOrderTypeId,
                text: data.WorkOrderTypeId + " - " + data.WorkOrderTypeDescription
            };
        }

        if (data.StartTime && data.EndTime) {
            const startDateTime = dateHelper.getTime(data.StartTime);
            const endDateTime = dateHelper.getTime(data.EndTime);

            this.startHour = startDateTime;
            this.endHour = endDateTime;
        } else {
            this.noHours = true;
        }
    }

    private clearForm(): void {
        this.comment = "";
        this.description = "";
        this.customer = null;
        this.date = dateHelper.getNow();
        this.workOrderDate = dateHelper.getNow();
        this.closingDate = null;

        if (this.editMode !== enumHelper.fieldServiceEditMode().SUB) {
            this.originalWO = null;
        }

        this.id = null;
        this.poNumber = "";
        this.project = null;
        this.reference = "";
        this.type = null;
    }
}
