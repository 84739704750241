import { bindable, transient , observable } from "aurelia-framework";
import { I18N } from "aurelia-i18n";
import routerHelper from "helpers/routerHelper";

import { default as documentHelper } from "helpers/documentHelper";
import { MaCardOptionsModel, MaCardLineModel, MaCardActionModel } from "components/layout/ma-card/ma-card-options.model";
import notificationHelper from "helpers/notificationHelper";
import RouteRepository from "repositories/routeRepository";
import { default as _ } from "underscore";

@transient()
export class DocumentList {
    @bindable public documents: any[] = [];
    @bindable public drawerTitleKey: string = "";
    @bindable public drawerTitle: string = "";
    @bindable public isDrawerOpen: boolean = false;
    @bindable public deleteAction?: (id: any) => Promise<boolean>;
    @bindable public readonly: boolean = false;
    @bindable public byCategory: boolean = false;
    @bindable public byCategoryDrawerOpen: boolean = true;

    @observable public filteredDocs: any;
    public documentsByCategories: any;
    public searchString: string | null = null;
    public isRefresh: boolean = false;
    public nocategory: string = "";

    constructor(private readonly i18n: I18N, private readonly routeRepository: RouteRepository) {
    }

    public bind(): void {
        if (this.byCategory) {
            this.nocategory = this.i18n.tr("NoCategory");
            this.groupBy("");
        }
    }

    public attached(): void {
        if (this.byCategory) {
            const input = document.getElementById("searchBox");

            if (input) {
                input.addEventListener("keydown", (event: any): boolean => {
                    if (event.key === "Enter") {
                        event.preventDefault();
                        this.goFilter();
                        return false;
                    } else {
                        this.isRefresh = true;
                        return true;
                    }
                });
            }
        }
    }

    public groupBy(searchstring: string): void {
        this.filteredDocs = this.documents;
        if (searchstring) {
            this.filteredDocs = this.documents.filter((doc: any) => doc.Name.toLowerCase().includes(searchstring.toLowerCase()) || doc.DateCreated.includes(searchstring));
        }

        this.documentsByCategories = _.sortBy([...new Set(this.filteredDocs.map((document: any) => document.Category))], (key: any) => key);
    }

    public getDocumentsByKey(category: string): any {
        return this.filteredDocs.filter((document: any) => document.Category === category);
    }

    public getDocumentDate(date: any): string {
        return documentHelper.formatDate(date);
    }

    public getDocumentIcon(type: any): string {
        return documentHelper.getIconClass(type);
    }

    public getDocumentSize(size: any): string {
        return documentHelper.formatDocumentSize(size);
    }

    public getDocumentType(type: any): string {
        return this.i18n.tr(documentHelper.getLocalizationKey(type));
    }

    public checkAndOpenDocument(item: any): void {
        const document = documentHelper.reformDocument(item);
        documentHelper.openDocument(document);
    }

    public toggleDrawer(): void {
        this.isDrawerOpen = !this.isDrawerOpen;
    }

    public editImage(item: any): void {
        routerHelper.navigateToRoute(this.routeRepository.routes.Document_Edit.name, { readonly: false, imageId: item.Id });
    }

    public goFilter(): void {
        if (this.searchString === null) {
            return;
        }
        this.groupBy(this.searchString);
        this.isRefresh = false;
    }

    public async searchChanged(val: any): Promise<void> {
        if (this.searchString === null) {
            return;
        }

        this.goFilter();
    }

    public createCard(item: any, sectionId: any): MaCardOptionsModel {
        const card = new MaCardOptionsModel({
            model: item,
            class: "ma-card-link",
            leftSectionIcon: this.getDocumentIcon(item.Type),
            leftSectionClass: "ml-3 mb-0 h2",
            cardBodyClass: "py-2",
            action: this.checkAndOpenDocument.bind(this, item),
            id: "card_" + item.Id,
            displayLines: [
                new MaCardLineModel({ display: item.Name, class: "text-primary font-weight-bold" }),
                new MaCardLineModel({ display: this.getDocumentInfoDisplay(item)})
            ],
            actionItems: [new MaCardActionModel({ id: "open", icon: "fa fa-chevron-right", action: this.checkAndOpenDocument.bind(this, item)})]
        });

        if (card.actionItems && item.Type === "IMAGE" && (sectionId === "self" || sectionId === "1") && !this.readonly) {
            card.actionItems.push(new MaCardActionModel({ id: "edit", icon: "fa fa-pencil pt-3", action: this.editImage.bind(this, item)}));
        }

        if (card.actionItems && this.deleteAction && (sectionId === "self" || sectionId === "1")) {
            card.actionItems.push(new MaCardActionModel({ id: "delete", icon: "fa fa-trash text-danger pt-3", action: this.delete.bind(this, item.Id) }));
        }

        return card;
    }

    private getDocumentInfoDisplay(item: any): string {
        const splitter = " | ";
        const subSplitter = " - ";

        const displayedInfo = [];
        const subInfo = [];

        displayedInfo.push(this.getDocumentDate(item.DateCreated));

        if (item.Summary !== "") {
            displayedInfo.push(item.Summary);
        }
        if (item.Category !== "") {
            subInfo.push(item.Category);
        }

        subInfo.push(this.getDocumentType(item.Type));

        if (item.Type !== "NOTE") {
            subInfo.push(this.getDocumentSize(item.Size));
        }

        displayedInfo.push(subInfo.join(subSplitter));

        return displayedInfo.join(splitter);
    }

    private async delete(id: any): Promise<void> {

        notificationHelper.showDialogYesNo(this.i18n.tr("msgDeleteDocumentPhoto"))
        .then(async (success: any) => {
            if (success) {
                if (this.deleteAction) {
                    await this.deleteAction(id).then((result: boolean) => {
                        if (result) {
                            this.documents = this.documents.filter((doc: any) => {
                                return doc.Id !== id;
                            });
                        }
                    });
                }
            }
        });
    }
}
