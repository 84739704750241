import _ from "underscore";
import moment from "moment";

import { I18N } from "aurelia-i18n";
import { observable } from "aurelia-binding";
import { Router } from "aurelia-router";
import { autoinject, bindable, PLATFORM } from "aurelia-framework";
import { validateTrigger, ValidationController, ValidationRules } from "aurelia-validation";

import settingRepository from "repositories/settingRepository";

import commonService from "services/commonService";
import tradesService from "services/tradesService";
import companyService from "services/companyService";
import activityService from "services/activityService";
import timesheetService from "services/timesheetService";
import { EquipmentService } from "services/equipment-service";
import { TimesheetService } from "services/timesheet-service";
import { default as defaultService } from "services/defaultService";
import timesheetEquipmentCounterService from "services/timesheetEquipmentCounterService";

import enumHelper from "helpers/enumHelper";
import dateHelper from "helpers/dateHelper";
import labelHelper from "helpers/labelHelper";
import routerHelper from "helpers/routerHelper";
import queryStringHelper from "helpers/queryStringHelper";
import UserAccessService from "services/user-access-service";
import { ValidationHelper } from "helpers/validation-helper";

import { WageTypeString } from "api/enums/wage-type-string";

import { TradeModel } from "api/models/company/trade-model";
import { ShiftModel } from "api/models/company/shift/shift-model";
import { BonusModel } from "api/models/company/bonus/bonus-model";
import { CompanyModel } from "api/models/common/company/company-model";
import { EquipmentModel } from "api/models/company/equipment/equipment-model";
import { ProjectBaseModel } from "api/models/company/project/project-base-model";
import { ProjectGroupModel } from "api/models/company/project/project-group-model";
import { TimesheetEntryModel } from "api/models/company/timesheet/timesheet-entry-model";
import { ProjectActivityModel } from "api/models/company/project/project-activity-model";
import { EquipmentCounterModel } from "api/models/company/equipment-counter-model";
import { TimeEntryEmployeeModel } from "api/models/company/employee/time-entry-employee-model";
import { TimesheetEntrySaveModel } from "api/models/company/timesheet/timesheet-entry-save-model";
import val from "core/val";
import { GroupService } from "services/group-service";
import { ProjectGroupType } from "api/enums/project-group-type";
import { NotificationHelper } from "helpers/notification-helper";
import { FormBase } from "pages/form-base";

@autoinject
export class TimesheetTimeEntry extends FormBase {

    @bindable public projectResultTemplate: string = PLATFORM.moduleName("pages/templates/maSelectTemplates/project_result.html");

    public emptyDate: string = "00:00";
    public defaultService: typeof defaultService = defaultService;

    public companies: any[] = [];
    public enumHelper: typeof enumHelper = enumHelper;
    public isAllowedToSeeEquipmentCounters: boolean = false;

    public allowEmployeeSelection: boolean = false;
    public isTeamLeader: boolean = false;
    public isResponsibleForTimeEntry: boolean = false;
    public isCreationMode: boolean = false;
    public CompanyToSpecify: boolean = false;
    public TimeEntryPrefix: string = "";
    public readonly: boolean = false;

    public isEquipmentTimeEntry: boolean = false;

    public shiftList: any[] = [];
    public subscriptions: any[] = [];

    public wageTypes: any[] = [];
    public timesheetId: number = 0;
    public timesheetEntryId: number = 0;
    public linkedTimesheetEntryId: number = 0;
    public showBonus: boolean = false;
    public projectCode: any;
    public payPeriodStart: any;
    public TimeEntryDate: any;
    public linkedEquipments: any[] = [];
    public unit: string | null = null;

    @observable
    public selectedHours: string = "";
    public canEditEquipment: boolean = false;
    public employee: any;
    public employeesD: any[] = [];
    public initialList: any[] = [];
    public dayPrefix: number = 0;
    public selectedFilter: any;
    public dataDto: TimesheetEntrySaveModel[] = [];
    public myActiveClass: string = "";
    public hoursEdition: boolean = false;
    public showOnlyEquipment: boolean = false;
    public employeeIdToEdit: number = 0; //????
    public counter1Value: number = 0;
    public counter2Value: number = 0;
    public listPeriodShown: string = this.timesheetHelperService.LIST_SHOW_CURRENT;
    public previousPayPeriod: boolean = this.listPeriodShown === this.timesheetHelperService.LIST_SHOW_PAST;

    //UNIFIED INTERFACE
    public startTime: string = "";
    public endTime: string = "00:00";
    public regularTime: number = 0;
    public halfTime: number = 0;
    public doubleTime: number = 0;
    @observable
    public selectedStartTime: any;
    public selectedEndTime: any;
    @observable
    public selectedRegularTime: Date | string = this.emptyDate;
    @observable
    public selectedHalfTime: Date | string = this.emptyDate;
    @observable
    public selectedDoubleTime: Date | string = this.emptyDate;

    // Counters informations
    public showEquipmentCounter: boolean = false;
    public linkedEquipmentsCounters: any[] | null = [];
    public counter1TypeDescription: string | null = "";
    public counter1PreviousValue: number | null = null;
    public counter1Type: string | null = "";
    public hasCounter1: boolean = false;
    public counter2TypeDescription: string | null = "";
    public counter2PreviousValue: number | null = null;
    public counter2Type: string | null = "";
    public hasCounter2: boolean = false;

    public targetPrefix: any;
    public project: any;
    public hoursActivity: any;
    public hoursGroup: any;
    public equipmentActivity: any;
    public bonusActivity: any;
    public equipment: any;
    public employees: any[] = [];
    public totalHours: number = 0;
    public bonus: any;
    public quantity: number = 0;
    public shift: number = 0;
    public wageType: string = "";
    @observable
    public comment: string | null = null;
    public employeeSelectedDays: any[] = [];
    public bonusSelectedDays: any[] = [];
    public equipmentGroup: any;
    public bonusGroup: any;
    public trade: any;
    public unModified: TimesheetEntrySaveModel[] | null = null;

    public selectedDays: any;

    public commentMaxLength: number = val.get("timesheets.timeentry.comment", "maxLength");

    public groups: any[] | null = null;

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router, private readonly validationController: ValidationController, private readonly validationHelper: ValidationHelper, public readonly timesheetHelperService: TimesheetService, private readonly equipmentService: EquipmentService, private readonly userAccessService: UserAccessService, private readonly groupService: GroupService) {
        super(notificationHelper, i18n, router);
        
    }

    public async activate(params: any): Promise<any> {
        this.initValidation();

        this.bindViewModel(params.timesheetId, params.timesheetEntryId, params.q);
        this.getAllowedExpenseCompanySelection();
        this.clear();
        await this.loadData();

        this.unModified = this.dataToSend(true);
    }

    public bind(): any {
        window.scrollTo(0, 1);
    }

    public bindViewModel(timesheetId: number, timesheetEntryId: number, querystring: string): any {
        this.timesheetId = timesheetId;
        this.timesheetEntryId = timesheetEntryId;

        //queryString Parsing
        const qs = routerHelper.getQuerystring(querystring);
        this.listPeriodShown = qs.ListPeriodShown;
        this.employee = JSON.parse(qs.Employee);
        this.linkedEquipments = JSON.parse(qs.LinkedEquipments);
        this.payPeriodStart = qs.PayPeriodStartDate;
        this.isTeamLeader = queryStringHelper.parseIsTeamLeaderTimesheet(querystring);
        this.isResponsibleForTimeEntry = queryStringHelper.IsResponsibleforTimeEntry(querystring);

        this.dayPrefix = this.getDayPrefix(this.payPeriodStart);
        this.isCreationMode = !(timesheetId && timesheetEntryId);
        this.allowEmployeeSelection = (this.isTeamLeader && this.isCreationMode);
        this.hoursEdition = false;
        this.showBonus = false;
        this.showOnlyEquipment = false;
        this.isAllowedToSeeEquipmentCounters = this.userAccessService.isOptionEnabledSync(enumHelper.userOptions.EQUIPMENTCOUNTERS);
        this.previousPayPeriod = this.listPeriodShown === this.timesheetHelperService.LIST_SHOW_PAST;

        this.initialList = [];
    }

    public async show(section: string): Promise<void> {
        if (this.checkDirty()) {
            const success = await this.notificationHelper.showDialogYesNo(this.i18n.tr("DoYouWantToSave"), "", false, {
                thirdButton: true,
                otherLabel: this.i18n.tr("Cancel")
            });

            if (success === true) {
                this.save(false);
            } else if (success === false) {
                this.clearData();
                this.showBonus = section === "bonus";
            }

        } else {
            this.showBonus = section === "bonus";
        }
    }

    public async save(navigateBack: boolean = true): Promise<void> {
        if ((this.employees.length === 0) && this.isResponsibleForTimeEntry && this.isTeamLeader && this.isCreationMode) {
            this.notificationHelper.showWarning(this.i18n.tr("YouNeedToSelectAnEmployee!"), this.i18n.tr("ValidationError"));
        } else {

            if (!(await this.validate())) {
                return;
            } else {
                routerHelper.showLoading();

                await timesheetService.setEntriesForTimesheet(this.dataToSend(), this.previousPayPeriod).then(() => {
                    routerHelper.hideLoading();
                    settingRepository.setTimesheetDefaultValues(JSON.stringify({
                        employees: this.employees,
                        currentPeriod: this.payPeriodStart
                    }));
                    this.initialList = [];

                    this.clearData();
                    this.unModified = null;
                    if (navigateBack) {
                        this.router.navigateBack();
                    } else {
                        this.show(this.showBonus ? "time" : "bonus");
                    }
                });
            }
        }
    }

    public get lookupEmployee(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                const getOnlyCompleted = this.isTeamLeader && !this.isResponsibleForTimeEntry;
                timesheetService.getEmployeeForTimesheet(this.payPeriodStart, getOnlyCompleted, params.data.filter, params.data.page || 1).then(
                    (result: TimeEntryEmployeeModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: TimeEntryEmployeeModel): any => {
                return {
                    id: item.Id,
                    text: `${item.Id} - ${item.FirstName} ${item.LastName}`,
                    data: {
                        LinkedEquipment: item.LinkedEquipment,
                        DefaultActivityId: item.DefaultActivityId,
                        DefaultGroupId: item.DefaultGroupId
                    }
                };
            }
        };
    }

    public get lookupProjects(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                let prefix: string = this.TimeEntryPrefix;
                if (this.CompanyToSpecify && this.targetPrefix) {
                    prefix = this.targetPrefix.id;
                }
                timesheetService.getProjectForTimesheet(prefix, params.data.filter, params.data.page || 1).then(
                    (result: ProjectBaseModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ProjectBaseModel): any => {
                let textDesciption = item.Id;
                if (item.Description) {
                    textDesciption = textDesciption + " - " + item.Description;
                }
                return {
                    id: item.Id,
                    text: textDesciption,
                    data: item
                };
            }
        };
    }

    public get lookupTrades(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                let prefix: string = this.TimeEntryPrefix;
                if (this.CompanyToSpecify && this.targetPrefix) {
                    prefix = this.targetPrefix.id;
                }
                tradesService.getTradesForEmployee(this.concernedEmployee(), params.data.filter, params.data.page || 1).then(
                    (result: TradeModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            }, mapResults: (item: TradeModel): any => {
                return {
                    id: item.Code,
                    text: `${item.Code} - ${item.Description}`,
                    DefaultActivityCode: item.DefaultActivityCode
                };
            }
        };
    }

    public get lookupActivity(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                activityService.getActivitiesForProject(this.project.id, params.data.filter, params.data.page || 1).then(
                    (result: ProjectActivityModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            }
        };
    }

    public get lookupEquipment(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                timesheetService.getEquipmentForTimesheet(this.getSingleEmployeeId()!, params.data.filter, params.data.page || 1).then( //???check employee Id might be null
                    (result: EquipmentModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: EquipmentModel): any => {
                return {
                    id: item.Code,
                    text: item.Code + " - " + item.Description
                };
            }
        };
    }

    public get lookupBonus(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                timesheetService.getBonusForTimesheet(params.data.filter, params.data.page || 1).then(
                    (result: BonusModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: BonusModel): any => {
                return {
                    id: item.Id,
                    text: item.Id + " - " + item.Description,
                    data: item

                    // quantity: 1,
                    // unit: item.Unit,
                    // data: item
                };
            }
        };
    }

    public get lookupEquipmentGroup(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                timesheetService.getEquipmentGroupsforTimesheet(params.data.filter, params.data.page || 1).then(
                    (result: ProjectGroupModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: ProjectGroupModel): any => {
                return {
                    id: item.Id,
                    text: item.Id + " - " + item.Description
                };
            }
        };
    }

    public get lookupCompanies(): any {
        return {
            transport: (params: any, success: any, failure: any): any => {
                companyService.getAll().then(
                    (result: CompanyModel[]) => {
                        return success(result);
                    },
                    (fail: any) => {
                        return failure(fail);
                    }
                );
            },
            mapResults: (item: CompanyModel): any => {
                return {
                    id: item.Prefix,
                    text: item.Name
                };
            }
        };
    }

    public async onSelectBonus(selectedItem: any): Promise<void> {
        this.bonusTSelection(selectedItem);
        await this.loadDefaultActivityForBonus();
        this.unit = selectedItem.data.Unit;
    }

    public onUnSelectBonus(): void {
        this.unit = "";
    }

    public async onEquipmentSelected(): Promise<void> {
        await this.loadDefaultActivityForEquipment();
        this.loadEquipmentCountersInfos(this.equipment.id);
    }

    public async onProjectSelected(selectedItem: any): Promise<void> {
        const defaultActivityForEmployeePromise = this.loadDefaultActivityForLabor();
        const defaultActivityForEquipmentPromise = this.loadDefaultActivityForEquipment();
        const defaultActivityForBonusPromise = this.loadDefaultActivityForBonus();
        await Promise.all([defaultActivityForEmployeePromise, defaultActivityForEquipmentPromise, defaultActivityForBonusPromise]);
        await this.loadDefaultGroupForLabor();
    }

    public async GetExpenseGroups(projectId: string | null): Promise<ProjectGroupModel[] | null> {

        let groups = await this.groupService.GetProjectGroups(projectId);

        if (!groups) {
            return [];
        }

        groups = groups!.filter((group: any) => group.TypeId === ProjectGroupType.Labour);

        return groups.map((item: any): any => {
            return {
                id: item.Id,
                text: `${item.Id} - ${item.Description}`,
                data: item
            };
        });
    }

    public async onCompanySelected(): Promise<void> {
        this.project = null;
    }

    public async removeEmployee(item: any): Promise<void> {
        const success = await this.notificationHelper.showConfirmation(this.i18n.tr("msg_DeleteEmployeeConfirmation"));

        if (success) {
            this.employees = this.employees.filter((emp: any) => emp.id !== item.id);

            if (item.id !== this.employee.id) {
                this.initialList = this.initialList.filter((emp: any) => emp.id !== item.id);
            }

            this.loadLinkedEquipmentsCountersInfos();

            const defaultActivityPromise = this.loadDefaultActivityForLabor();
            const defaultGroupForEmployeePromise = this.loadDefaultGroupForLabor();
            const defaultTradePromise = this.loadDefaultTrade();
            await Promise.all([defaultActivityPromise, defaultTradePromise, defaultGroupForEmployeePromise]);
        }
    }

    public async addEmployees(): Promise<void> {
        const tempList: any = [];

        this.employeesD.forEach( (empl: any): any => {
            if (empl.data.data) {
                tempList.push(empl.data);
            } else {
                tempList.push(empl);
            }
        });

        const selectionWithoutSelf = _.reject(tempList, (item: any) => item.data.Id === this.employee.Id);
        this.initialList = _.union(this.initialList, selectionWithoutSelf);
        this.employees = tempList;

        this.loadLinkedEquipmentsCountersInfos();

        const defaultActivityPromise = this.loadDefaultActivityForLabor();
        const defaultGroupForEmployeePromise = this.loadDefaultGroupForLabor();
        const defaultTradePromise = this.loadDefaultTrade();
        await Promise.all([defaultActivityPromise, defaultTradePromise, defaultGroupForEmployeePromise]);
    }

    // On empêche les retours de ligne car ceux-ci ne sont pas affichables dans maestro*
    public commentChanged(value: string): void {
        if (this.comment !== null) {
            this.comment = value.replace(/\n/g, "");
        }
    }

    public selectedStartTimeChanged(value: string): void {
        if (value && value !== " ") {
            this.startTime = value;
            this.updateTotalHours();
        }
    }

    public selectedRegularTimeChanged(value: string): void {
        if (this.isCreationMode && value) {
            this.regularTime = this.getWorkTime(value);
            this.updateTotalHours();
        }
    }

    public selectedHalfTimeChanged(value: string): void {
        if (this.isCreationMode && value) {
            this.halfTime = this.getWorkTime(value);
            this.updateTotalHours();
        }
    }

    public selectedDoubleTimeChanged(value: string): void {
        if (this.isCreationMode && value) {
            this.doubleTime = this.getWorkTime(value);
            this.updateTotalHours();
        }
    }

    public selectedHoursChanged(value: string): void {
        if (!this.isCreationMode && value) {
            this.totalHours = this.getWorkTime(value);
            this.totalHours = this.totalHours;
            this.totalHours = this.totalHours;
            if (this.startTime !== "") {
                this.endTime = this.getEndTime(this.startTime, this.totalHours);
            }
        }
    }

    public wageTypeClicked(id: string): void {
        this.wageType = id;
    }

    public existInSelect(id: number): boolean {
        return _.contains(this.selectedDays, id);
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        if (!this.unModified) {
            return false;
        }

        if (!this.isCreationMode) {
            const stringifyUnmodified = JSON.stringify(this.unModified).replace(/[^0-9A-Z]+/gi, "");
            const stringifyCurrent = JSON.stringify(this.dataToSend(this.employees.length === 0)).replace(/[^0-9A-Z]+/gi, "");

            return stringifyUnmodified !== stringifyCurrent;
        }

        if (this.hoursActivity ||
            this.hoursGroup ||
            this.equipmentActivity ||
            this.bonusActivity ||
            this.equipment ||
            this.totalHours !== 0 ||
            this.bonus ||
            this.quantity ||
            (this.employeeSelectedDays.length !== 0) ||
            (this.bonusSelectedDays.length !== 0)) {
            return true;
        } else {
            return false;
        }
    }

    private async loadData(): Promise<void> {
        await Promise.all([this.loadShifts(), this.loadEntry()]);

        await this.initCompanies();
    }

    private async loadShifts(): Promise<void> {
        const data = await commonService.getShifts();

        const shifList = _.map(data, (shift: ShiftModel) => {
            return {
                id: shift.Id,
                text: shift.Id + " - " + shift.Description
            };
        });

        this.shiftList = shifList;
        if (this.shiftList.length > 0) {
            this.shift = this.shiftList[0].id;
        }
    }

    private async loadEntry(): Promise<void> {
        if (this.isCreationMode) {
            if (this.isTeamLeader && this.isCreationMode) {
                this.loadStoredEmployeeList();
            }

            this.TimeEntryPrefix = settingRepository.getPrefix();

            this.canEditEquipment = true;

            this.loadLinkedEquipmentsCountersInfos();

            const defaultActivityPromise = this.loadDefaultActivityForLabor();
            const defaultGroupForEmployeePromise = this.loadDefaultGroupForLabor();
            const defaultTradePromise = this.loadDefaultTrade();
            await Promise.all([defaultActivityPromise, defaultTradePromise, defaultGroupForEmployeePromise]);
            return;
        }
        const loadedData: TimesheetEntryModel = await timesheetService.getTimesheetEntryToLoad(this.timesheetId, this.timesheetEntryId);

        if (loadedData && loadedData.Prefix) {
            this.TimeEntryPrefix = loadedData.Prefix;
        }

        if (loadedData.Bonus) {
            this.loadBonus(loadedData);
        } else if (loadedData.EmployeeActivity) {
            this.groups = await this.GetExpenseGroups(loadedData.ProjectId);
            this.loadHours(loadedData);
        } else {
            this.canEditEquipment = true;
            this.isEquipmentTimeEntry = true;

            this.loadEquipments(loadedData);
        }
    }

    private loadStoredEmployeeList(): void {
        const savedEmployee = JSON.parse(settingRepository.getTimesheetDefaultValues());
        if (savedEmployee && savedEmployee.employees[0].text) {
            if (this.currentPeriodEmployeeList(savedEmployee.currentPeriod)) {
                this.employees = savedEmployee.employees;
                //map to add selected()
                _.map(this.employees, (entry: any) => {
                    return _.extend(entry, {
                        selected: true
                    });
                });
                this.initialList.push(...this.employees);
            } else {
                this.initializeInitialList(this.constructData());
            }
        } else {
            this.initializeInitialList(this.constructData());
        }
    }

    private loadBonus(data: TimesheetEntryModel): void {
        this.showBonus = true;
        this.project = {
            id: data.ProjectId,
            text: data.ProjectId + " - " + data.ProjectDescription
        };
        this.bonusActivity = {
            id: data.BonusActivity,
            text: data.BonusActivity + " - " + data.BonusActivityDescription
        };
        this.bonus = {
            id: data.Bonus,
            text: data.Bonus + " - " + data.BonusDescription,
            DefaultActivityCode: data.BonusActivity
        };
        this.quantity = data.BonusQuantity;
        this.unit = data.BonusUnit;
        this.comment = data.Comment;
        this.TimeEntryDate = data.TimeEntryDate;
        this.employeeIdToEdit = data.EmployeeId;
    }

    private loadHours(loadedData: TimesheetEntryModel): void {
        this.hoursEdition = true;
        const date = dateHelper.getDate();
        date.setMinutes(loadedData.Hours * 60);
        const workedHours = dateHelper.formatDate("HH:mm", date);
        this.project = {
            id: loadedData.ProjectId,
            text: loadedData.ProjectId + " - " + loadedData.ProjectDescription
        };
        this.trade = {
            id: loadedData.TradeId,
            text: loadedData.TradeId + " - " + loadedData.TradeDescription
        };
        this.hoursActivity = {
            id: loadedData.EmployeeActivity,
            text: loadedData.EmployeeActivity + " - " + loadedData.EmployeeActivityDescription
        };
        this.hoursGroup = loadedData.EmployeeGroup ? {
            id: loadedData.EmployeeGroup,
            text: loadedData.EmployeeGroup + " - " + loadedData.EmployeeGroupDescription
        } : null;
        this.wageType = loadedData.RateType!;
        this.shift = loadedData.Shift;

        this.selectedHours = workedHours.toString();
        this.totalHours = loadedData.Hours;

        this.canEditEquipment = loadedData.CanEditEquipment;

        this.comment = loadedData.Comment;
        this.TimeEntryDate = loadedData.TimeEntryDate;
        this.employeeIdToEdit = loadedData.EmployeeId;

        this.linkedTimesheetEntryId = loadedData.LinkedTimesheetEntryId;
        this.fillLinkedEquipmentsCounter(loadedData.LinkedEquipmentsCounters);

        this.selectedStartTime = loadedData.StartTime ? dateHelper.formatDate("HH:mm", moment(loadedData.StartTime).toDate()) : "";
        this.selectedEndTime = loadedData.EndTime ? dateHelper.formatDate("HH:mm", moment(loadedData.EndTime).toDate()) : "";
    }

    private async loadDefaultActivityForLabor(): Promise<void> {
        this.hoursActivity = null;

        const project = this.project;
        const defaultActivityIdList = _.chain(this.employees.filter((emp: any) => emp.data))
            .map((emp: any) => emp.data.DefaultActivityId)
            .uniq()
            .filter((defaultActivityId: any) => defaultActivityId !== "")
            .value();

        if (defaultActivityIdList.length !== 1) {
            if (this.employee.DefaultActivityId && defaultActivityIdList.length === 0) {
                defaultActivityIdList[0] = this.employee.DefaultActivityId;
            } else {
                // If there is zero or multiple default activities let the user choose.
                return;
            }
        }

        const activityCode = _.first(defaultActivityIdList);

        if (!activityCode) {
            return;
        }

        let activity: ProjectActivityModel;

        if (project) {
            activity = await activityService.getActivityForProject(project.id, activityCode);
        } else {
            activity = await activityService.getActivity(activityCode);
        }

        if (!activity) {
            this.notificationHelper.showError(this.i18n.tr("err_DefaultActivityNotExistForProject"));
            return;
        }

        if (project && !activity.AvailableInMobileForLabour) {
            this.notificationHelper.showError(this.i18n.tr("err_DefaultActivityNotAvailableForMobile"));
            return;
        }

        this.hoursActivity = {
            id: activity.Id,
            text: activity.Id + " - " + activity.Description
        };
    }

    private async loadDefaultGroupForLabor(): Promise<void> {
        this.hoursGroup = null;

        if (!this.project) {
            return;
        }

        this.groups = await this.GetExpenseGroups(this.project.id);

        const defaultGroupIdList = _.chain(this.employees.filter((emp: any) => emp.data))
            .map((emp: any) => emp.data.DefaultGroupId)
            .uniq()
            .filter((DefaultGroupId: any) => DefaultGroupId !== "")
            .value();

        if (defaultGroupIdList.length !== 1) {
            if (this.employee.DefaultGroupId && defaultGroupIdList.length === 0) {
                defaultGroupIdList[0] = this.employee.DefaultGroupId;
            } else {
                // If there is zero or multiple default activities let the user choose.
                return;
            }
        }

        const groupCode = _.first(defaultGroupIdList);

        if (!groupCode) {
            return;
        }

        const group = this.groups!.find((groupItem: any) => groupItem.id === groupCode);

        if (!group) {
            return;
        }
 
        this.hoursGroup = {
            id: group.id,
            text: group.text
        };
    }

    private async loadDefaultTrade(): Promise<void> {
        const employeeId = this.getSingleEmployeeId();

        if (!employeeId) {
            this.trade = null;
            return;
        }

        const defaultTrade: TradeModel = await tradesService.getEmployeeDefaultTrade(employeeId);

        if (defaultTrade) {
            this.trade = {
                id: defaultTrade.Code,
                text: defaultTrade.Code + " - " + defaultTrade.Description
            };
        }
    }

    private async loadDefaultActivityForEquipment(): Promise<void> {
        this.equipmentActivity = null;

        const project = this.project;
        const equipment = this.equipment;

        if (!project || !project.id || !equipment || !equipment.data.DefaultActivityId) {
            // Cannot load activities without project.
            return;
        }

        const activity = await activityService.getActivityForProject(project.id, equipment.data.DefaultActivityId);

        if (!activity) {
            return;
        }

        this.equipmentActivity({
            id: activity.Id,
            text: activity.Id + " - " + activity.Description
        });
    }

    private loadEquipments(loadedData: TimesheetEntryModel): void {
        const date = dateHelper.getDate();
        date.setMinutes(loadedData.Hours * 60);
        const workedHours = dateHelper.formatDate("HH:mm", date);

        this.showOnlyEquipment = true;
        this.project = {
            id: loadedData.ProjectId,
            text: loadedData.ProjectId + " - " + loadedData.ProjectDescription
        };
        this.equipmentActivity = {
            id: loadedData.EquipmentActivity,
            text: loadedData.EquipmentActivity + " - " + loadedData.EquipmentActivityDescription
        };
        this.equipment = {
            id: loadedData.EquipmentId,
            text: loadedData.EquipmentId + " - " + loadedData.EquipmentDescription
        };
        this.wageType = loadedData.RateType!;
        this.shift = loadedData.Shift;

        this.selectedHours = workedHours.toString();
        this.totalHours = loadedData.Hours;

        this.comment = loadedData.Comment;
        this.TimeEntryDate = loadedData.TimeEntryDate;
        this.employeeIdToEdit = loadedData.EmployeeId;

        // Equipment counter
        this.counter1Value = loadedData.Counter1Value;
        this.counter2Value = loadedData.Counter2Value;

        this.linkedTimesheetEntryId = loadedData.LinkedTimesheetEntryId;
        this.loadEquipmentCountersInfos(loadedData.EquipmentId!);

        this.selectedStartTime = loadedData.StartTime ? dateHelper.formatDate("HH:mm", moment(loadedData.StartTime).toDate()) : "";
        this.selectedEndTime = loadedData.EndTime ? dateHelper.formatDate("HH:mm", moment(loadedData.EndTime).toDate()) : "";
    }

    private loadEquipmentCountersInfos(equipmentId: string): void {
        this.equipmentService.getEquipmentWithCounters(equipmentId).then((result: EquipmentCounterModel) => {
            if (result.EquipmentId != null) {
                this.counter1TypeDescription = result.Counter1TypeDescription;
                this.counter1PreviousValue = result.Counter1PreviousValue;
                this.counter1Type = result.Counter1Type;
                this.hasCounter1 = result.HasCounter1;

                this.counter2TypeDescription = result.Counter2TypeDescription;
                this.counter2PreviousValue = result.Counter2PreviousValue;
                this.counter2Type = result.Counter2Type;
                this.hasCounter2 = result.HasCounter2;

                this.showEquipmentCounter = true;
            }   else {
                this.counter1Value = 0;
                this.counter2Value = 0;

                this.showEquipmentCounter = false;
            }});
    }

    private loadLinkedEquipmentsCountersInfos(): void {
        if (this.employees.length > 1) {
            this.linkedEquipmentsCounters = null;
            return;
        }

        // In allowSelectionEmployeeMode, we use the employees list
        const employeeId = this.employees.length === 1 ? this.employees[0].id : null;

        timesheetEquipmentCounterService.getTimesheetEmployeeLinkedEquipmentsWithCounters(employeeId || this.employeeIdToEdit || this.employee.Id).then((results: EquipmentCounterModel[]) => {
                this.fillLinkedEquipmentsCounter(results);
            });
    }

    private async loadDefaultActivityForBonus(): Promise<void> {
        this.bonusActivity = null;
        let defaultActivityCode: string | null = null;

        const project = this.project;
        const bonus = this.bonus;

        if (!bonus) {
            return;
        }

        if (bonus.data) {
            defaultActivityCode = bonus.data.DefaultActivityCode;
        } else {
            defaultActivityCode = bonus.DefaultActivityCode;
        }

        if (!defaultActivityCode) {
            const defaultActivityIdList = _.chain(this.employees.filter((emp: any) => emp.data))
                .map((emp: any) => emp.data.DefaultActivityId)
                .uniq()
                .filter((defaultActivityId: any) => defaultActivityId !== "")
                .value();

            if (defaultActivityIdList.length !== 1) {
                if (this.employee.DefaultActivityId && defaultActivityIdList.length === 0) {
                    defaultActivityIdList[0] = this.employee.DefaultActivityId;
                } else {
                    // If there is zero or multiple default activities let the user choose.
                    return;
                }
            }

            defaultActivityCode = _.first(defaultActivityIdList);
        }

        if (!project || !project.id || !defaultActivityCode) {
            // Cannot load activities without project.
            return;
        }

        const activity = await activityService.getActivityForProject(project.id, defaultActivityCode);

        if (!activity) {
            return;
        }

        if (project && !activity.AvailableInMobileForLabour) {
            return;
        }

        this.bonusActivity = {
            id: activity.Id,
            text: activity.Id + " - " + activity.Description
        };
    }

    private async initCompanies(): Promise<any> {
        if (!this.CompanyToSpecify) {
            this.targetPrefix = null;
            return;
        }

        return companyService.getAll().then((data: CompanyModel[]) => {
                const list = _.map(data, (item: CompanyModel) => {
                    return { id: item.Prefix, tag: item.Tag, text: item.Name };
                });

                this.companies = list;
                let selectedCompanyPrefix = settingRepository.getPrefix();

                if (this.TimeEntryPrefix) {
                    selectedCompanyPrefix = this.TimeEntryPrefix;
                }

                if (selectedCompanyPrefix) {
                    this.targetPrefix  = this.companies.find((company: any) => company.id === selectedCompanyPrefix);
                }
                return;
            },
            (reason: any) => {
                this.companies = [];
                return Promise.reject(new Error(this.i18n.tr("err_CompanyNotFound")));
            }
        );
    }

    private initValidation(): void {
        this.validationController.validateTrigger = validateTrigger.manual;

        ValidationRules
        .ensure("targetPrefix").required().when(() => this.CompanyToSpecify).withMessageKey("err_CompaniesSelectionRequired")
        .ensure("project").required().withMessageKey("err_ProjectRequired")
        .then().satisfies(() => this.hoursActivity || this.equipmentActivity || this.bonusActivity).withMessageKey("err_ActivityRequired")
        .ensure("hoursActivity").required().when(() => !this.hoursActivity && !this.showBonus && !this.equipmentActivity).withMessageKey("err_HoursActivityRequired") //double check
        .ensure("totalHours").satisfies(() => this.totalHours > 0).when(() => (this.hoursActivity || this.equipmentActivity) && !this.showBonus).withMessageKey("err_TimeSpentOrEquipmentHoursRequired")
        .ensure("employeeSelectedDays").satisfies(() => this.employeeSelectedDays.length > 0).when(() => this.isCreationMode && !this.showBonus).withMessageKey("err_YouMustSelectADay")
        .ensure("bonusSelectedDays").satisfies(() => this.bonusSelectedDays.length > 0).when(() => this.isCreationMode && this.showBonus).withMessageKey("err_YouMustSelectADay")
        .ensure("equipment").required().when(() => !this.showBonus && this.equipmentActivity).withMessageKey("err_EquipmentRequired")
        .ensure("equipmentActivity").required().when(() => !this.showBonus && this.equipment).withMessageKey("err_EquipmentActivityRequired")
        .ensure("bonus").required().when(() => this.showBonus && (this.quantity >= 0 || this.bonusActivity)).withMessageKey("err_BonusRequired")
        .ensure("bonusActivity").required().when(() => this.showBonus && this.bonus).withMessageKey("err_BonusActivityRequired")
        .ensure("quantity").required().when(() => this.showBonus && (this.bonus || this.bonusActivity)).withMessageKey("err_BonusQtyRequired")
        .then().satisfies(() => this.quantity > 0).when(() => this.showBonus && this.bonus).withMessageKey("err_MinQuantity")
        .then().satisfies(() => this.quantity !== 0).when(() => this.showBonus && (this.bonus || this.bonusActivity)).withMessageKey("err_BonusRequired")
        .on(this);
    }

    private initializeInitialList(entries: any[]): void {
        this.initialList[0] = entries;
    }

    private clear(): void {
        this.targetPrefix = null;
        this.project = null;
        this.trade = null;
        this.hoursGroup = null;
        this.hoursActivity = null;
        this.equipmentActivity = null;
        this.bonusActivity = null;
        this.employees = [];
        this.equipment = null;
        this.totalHours = 0;
        this.bonus = null;
        this.unit = null;
        this.quantity = 0;
        this.shift = 0;
        this.comment = null;
        this.wageType = WageTypeString.Simple;
        this.employeeIdToEdit = 0;
        this.equipmentGroup = null;
        this.bonusGroup = null;
        this.employeeSelectedDays = [];
        this.bonusSelectedDays = [];
    }

    private clearData(): void {
        this.trade = null;
        this.hoursGroup = null;
        this.hoursActivity = null;
        this.equipmentActivity = null;
        this.bonusActivity = null;
        this.equipment = null;
        this.totalHours = 0;
        this.bonus = null;
        this.quantity = 0;
        this.shift = 0;
        this.comment = "";
        this.wageType = WageTypeString.Simple;
        this.equipmentGroup = null;
        this.bonusGroup = null;
        this.employeeSelectedDays = [];
        this.bonusSelectedDays = [];

        this.selectedHours = this.emptyDate;
        this.selectedRegularTime = this.emptyDate;
        this.selectedHalfTime = this.emptyDate;
        this.selectedDoubleTime = this.emptyDate;
    }

    private dataToSend(init: boolean = false): TimesheetEntrySaveModel[] {
        //if no employee has been selected, make the changes on the current employee
        this.dataDto = [];
        if (this.employees.length === 0 && !init) {
            this.employees.push({
                id: this.isCreationMode ? this.employee.Id : this.employeeIdToEdit
            });
        }

        //initialize incremental days in edition Mode
        if (!this.isCreationMode) {
            this.bonusSelectedDays = [];
            this.employeeSelectedDays = [];
            this.bonusSelectedDays.push(0);
            this.employeeSelectedDays.push(0);
        }

        const workTime = this.totalHours;

        if (this.showBonus) {
            this.getBonus();
        } else {
            if (this.hoursActivity) {
                if (!this.isCreationMode) {
                    this.getHours(workTime);
                } else {
                    this.getHoursUnified();
                }
            }
            if (this.equipment) {
                this.dataDto.push(...this.getEquipment(workTime));
            }
        }
        return this.dataDto;
    }

    private constructData(): any {
        return {
            id: this.employee.Id,
            text: this.employee.Id + " - " + this.employee.FirstName + " " + this.employee.LastName,
            type: 1,
            selected: true,
            data: {
                Id: this.employee.Id,
                LastName: this.employee.FirstName,
                FirstName: this.employee.LastName,
                LinkedEquipment: this.linkedEquipments,
                DefaultActivityId: this.employee.DefaultActivityId,
                DefaultGroupId: this.employee.DefaultGroupId
            }
        };
    }

    private fillLinkedEquipmentsCounter(linkedEquipmentsCountersResults: Array<EquipmentCounterModel | null> | null): void {
        if (!linkedEquipmentsCountersResults || linkedEquipmentsCountersResults.length === 0) {
            this.linkedEquipmentsCounters = null;
            return;
        }

        const arrayCounter: any[] = [];

        _.each(linkedEquipmentsCountersResults,
            (result: EquipmentCounterModel) => {
                arrayCounter.push({
                    EquipmentId: result.EquipmentId,
                    EquipmentDescription: result.EquipmentDescription,
                    Counter1Value: result.Counter1Value,
                    Counter1TypeDescription: result.Counter1TypeDescription,
                    Counter1PreviousValue: result.Counter1PreviousValue,
                    Counter1Type: result.Counter1Type,
                    HasCounter1: result.HasCounter1,
                    Counter2Value: result.Counter2Value,
                    Counter2TypeDescription: result.Counter2TypeDescription,
                    Counter2PreviousValue: result.Counter2PreviousValue,
                    Counter2Type: result.Counter2Type,
                    HasCounter2: result.HasCounter2
                });
            });

        this.linkedEquipmentsCounters = arrayCounter;
    }

    private updateTotalHours(): void {
        if (this.isCreationMode) {
            this.totalHours = this.regularTime + this.halfTime + this.doubleTime;
        } else {
            this.totalHours = this.getWorkTime(this.selectedHours);
        }

        this.updateEndTime();
    }

    private updateEndTime(): void {
        if (this.startTime !== "") {
            this.endTime = this.getEndTime(this.startTime, this.totalHours);
            this.selectedEndTime = this.getDateTime(this.endTime);
        }
    }

    private getEquipment(workTime: number): any[] {
        return _.map(this.employeeSelectedDays, (day: any) => {
            const calculatedEntryDate = dateHelper.addDay(this.payPeriodStart, this.getDayToAdd(day));
            const data = {
                TimesheetId : this.timesheetId,
                TimesheetEntryId : this.isEquipmentTimeEntry ? this.timesheetEntryId : 0,
                LinkedTimesheetEntryId : this.linkedTimesheetEntryId,
                EmployeeId: this.employees.length === 0 ? 0 : this.employees[0].id,
                EquipmentId : this.equipment.id,
                TargetPrefix : this.CompanyToSpecify ? this.targetPrefix.id : this.TimeEntryPrefix,
                ProjectId : this.project.id,
                EquipmentActivity : this.equipmentActivity.id,
                TimeEntryDate : this.isCreationMode ? dateHelper.formatDateToSend(calculatedEntryDate) : this.TimeEntryDate,
                Hours : workTime,
                Comment : this.comment,
                Counter1Value : this.counter1Value,
                Counter2Value : this.counter2Value,
                RateType : WageTypeString.Equipment,
                StartTime : this.startTime,
                EndTime : this.endTime
            };
            return data;
        });
    }

    private gethoursbyEmployee(workTime: number, day: number, wageType: string, startTime: string, endTime: string): any[] {
        return _.map(this.employees, (employee: any) => {
                const calculatedEntryDate = dateHelper.addDay(this.payPeriodStart, day);
                const data = {
                    TimesheetId : this.timesheetId,
                    TimesheetEntryId : this.timesheetEntryId,
                    LinkedTimesheetEntryId : this.linkedTimesheetEntryId,
                    EmployeeId : employee.id,
                    RateType : wageType ? wageType : this.wageType,
                    TargetPrefix : this.CompanyToSpecify ? this.targetPrefix.id : this.TimeEntryPrefix,
                    ProjectId : this.project.id,
                    EmployeeActivity: this.hoursActivity.id,
                    EmployeeGroup: this.hoursGroup ? this.hoursGroup.id : null,
                    Shift : this.shift,
                    TradeId : this.trade && (this.employees.length < 2) ? this.trade.id : "",
                    TimeEntryDate : this.isCreationMode ? dateHelper.formatDateToSend(calculatedEntryDate) : this.TimeEntryDate,
                    Hours : workTime,
                    Comment : this.comment,
                    LinkedEquipmentsCounters : this.getLinkedEquipments(),
                    StartTime : startTime,
                    EndTime : endTime
                };
                return data;
            });
    }

    private getBonusByEmployee(workTime: number, day: number): any[] {
        return _.map(this.employees,
            (employee: any) => {
                const calculatedEntryDate = dateHelper.addDay(this.payPeriodStart, day);
                const data = {
                    TimesheetId : this.timesheetId,
                    TimesheetEntryId : this.timesheetEntryId,
                    EmployeeId : employee.id,
                    TargetPrefix : this.CompanyToSpecify ? this.targetPrefix.id : this.TimeEntryPrefix,
                    ProjectId : this.project.id,
                    Bonus : this.bonus.id,
                    BonusQuantity : this.quantity,
                    BonusActivity : this.bonusActivity.id,
                    TimeEntryDate : this.isCreationMode ? dateHelper.formatDateToSend(calculatedEntryDate) : this.TimeEntryDate,
                    Comment : this.comment,
                    RateType : WageTypeString.Bonus,
                    StartTime : this.startTime,
                    EndTime : this.endTime,
                };
                return data;
            });
    }

    private getDayToAdd(widgetChoice: number): number {
        const dayToAdd = widgetChoice >= this.dayPrefix ? (widgetChoice - this.dayPrefix) % 7 : ((7 + widgetChoice - this.dayPrefix) % 7);
        return dayToAdd;
    }

    private  getHours(workTime: number): any {
        return _.map(this.employeeSelectedDays, (day: any) => {
                this.dataDto.push(...this.gethoursbyEmployee(workTime, this.getDayToAdd(day), this.wageType, this.startTime, this.endTime));
            });
    }

    private getBonus(workTime?: any): any {
        return _.map(this.bonusSelectedDays, (day: any) => {
                this.dataDto.push(...this.getBonusByEmployee(workTime, this.getDayToAdd(day)));
            });
    }

    private getEndTime(strStartTime: string, strDurationInHours: number): string {
        return dateHelper.getTime(dateHelper.addHour(this.getDateTime(strStartTime), strDurationInHours));
    }

    private getDateTime(time: string): Date {
        const dateTime = new Date();
        dateTime.setHours(Number.parseInt(time.split(":")[0]));
        dateTime.setMinutes(Number.parseInt(time.split(":")[1]));
        return dateTime;
    }

    private getHoursUnified(): any {
        const usedWages =
            _.filter([
                { wage: WageTypeString.Simple, workedHours: this.regularTime, startTime: this.startTime, endTime: this.endTime },
                { wage: WageTypeString.Over, workedHours: this.halfTime, startTime: this.startTime, endTime: this.endTime },
                { wage: WageTypeString.Double, workedHours: this.doubleTime, startTime: this.startTime, endTime: this.endTime }],
                (wage: any) => wage.workedHours && wage.workedHours > 0);

        let startTime = this.startTime;
        let endTime = this.endTime;

        return _.map(usedWages, (workedWage: any) => {
                let setStartEndTime = true;
                return _.map(this.employeeSelectedDays, (day: any) => {
                            if (this.startTime !== "" && setStartEndTime) {
                                if (workedWage.wage !== WageTypeString.Simple) {
                                    startTime = endTime;
                                }
                                endTime = this.getEndTime(startTime, workedWage.workedHours);
                                setStartEndTime = false;
                            }

                            this.dataDto.push(...this.gethoursbyEmployee(workedWage.workedHours, this.getDayToAdd(day), workedWage.wage, startTime, endTime));
                        });
            });
    }

    private getWorkTime(time: string): number {
        return moment(time, "hh:mm").diff(moment().startOf("day"), "minutes") / 60;
    }

    private getLinkedEquipments(): any {
        return _.map(this.linkedEquipmentsCounters!, (linkedEquipment: any) => {
            const data = {
                TimesheetId : this.timesheetId,
                EquipmentId : linkedEquipment.EquipmentId,
                Counter1Value : +linkedEquipment.Counter1Value,
                Counter2Value : +linkedEquipment.Counter2Value
            };
            return data;
        });
    }

    private async getAllowedExpenseCompanySelection(): Promise<void> {
        this.CompanyToSpecify = await timesheetService.getAllowedExpenseCompanySelection();
    }

    private bonusTSelection(item: any): void {
        if (item.data) {
            this.bonus = {
                id: item.data.Id,
                text: item.data.Id + " - " + item.data.Description,
                data: item.data
            };
            this.quantity = 1;
            this.unit = item.data.Unit;
        }
    }

    private concernedEmployee(): any {
        if (this.isCreationMode) {
            if (this.employees.length === 0) {
                return this.employee.Id;
            } else if (this.employees.length === 1) {
                return this.employees[0].id;
            }
        }

        return this.employeeIdToEdit;
    }

    private getDayPrefix(startDate: any): any {
        const date = dateHelper.dateFromUTC(startDate);
        return date.getDay();
    }

    private getSingleEmployeeId(): number | null {
        // No employee selected. Use current user.
        // Only one employee selected. Return him
        if (this.employees.length === 0) {
            return this.employee.Id;
        } else if (this.employees.length === 1) {
            return _.first(this.employees).id;
        }

        // More than 1 selected. Return null
        return null;
    }

    private currentPeriodEmployeeList(period: any): any {
        return this.payPeriodStart === period;
    }

    private async validate(): Promise<boolean> {
        this.validationController.addObject(this);
        const isValid = await this.validationHelper.validateControllerAndNotifyUserIfNecessary(this.validationController);
        this.validationController.removeObject(this);
        return isValid;
    }
}
