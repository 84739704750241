define([
        "services/requests",
        "helpers/apiHelper"
],
    function (requests, apiHelper) {
        "use strict";

        var exports = {
            listForSummary: function (workOrderId, language) {
                var url = apiHelper.getWorkOrderBaseUrl(workOrderId) + "/summary/timesheets";
                if (language) {
                    url = url + "?lang=" + language;
                }
                return requests.getJson( url );
            },

            getBySummaryWorkOrderId: function (workOrderId) {
                return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/timesheetsSummary");
            },

            getByWorkOrderIdAndLineNo: function (workOrderId, lineNo) {
                return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/timesheets/"+lineNo);
            },

            getByWorkOrderIdTypeAndId: function (workOrderId, isEmployee, id) {
                return requests.getJson(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/" + (isEmployee ? "employee" : "equipment") + "/" + id + "/timesheets");
            },

            setWorkorderTimeEntry: function (workOrderId, data) {
                return requests.post(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/timesheets/", data);
            },

            deleteWorkorderTimeEntry: function (workOrderId, lineNo, timestamp) {
                return requests.remove(apiHelper.getWorkOrderBaseUrl(workOrderId) + "/timesheets/" + lineNo + "?timesheetTimestamp=" + encodeURIComponent(timestamp));
            },

            getHoursSummaryByProjectCodeAndDispatchDate: function (dispatchProjectCode, dispatchDate) {
                return requests.getJson(apiHelper.getProjectDailyEntryBaseUrl(dispatchProjectCode, dispatchDate) +  "/timeentriessummary");
            },

            getHoursSummary: function (dispatchDate, maxDispatchDate) {
                return requests.getJson(apiHelper.getBaseUrl() + "/workorders/" + dispatchDate + "/" + maxDispatchDate + "/hours-summary");
            },
        };

        return exports;
    });