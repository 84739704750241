import routerHelper from "helpers/routerHelper";
import { autoinject } from "aurelia-framework";
import materialRequisitionService from "services/materialRequisitionService";
import { I18N } from "aurelia-i18n";
import { MaterialRequisitionModel } from "api/models/company/material-requisition/material-requisition-model";
import { NotificationHelper } from "helpers/notification-helper";
import { Router } from "aurelia-router";
import { FormBase } from "pages/form-base";
import queryStringHelper from "helpers/queryStringHelper";

@autoinject
export class ProjectMaterialRequisitionMemo extends FormBase {
    public readonly: boolean = false;
    public dispatchProjectCode: any = null;
    public dispatchId: number = 0;
    public workOrderId: string = "";
    public requisitionId: string = "";
    public element: HTMLTextAreaElement | null = null;
    public requisition!: MaterialRequisitionModel;
    private minPaddingNoScroll: number = 2;
    private originalRequisitionDetails: string | null = "";

    constructor(i18n: I18N, notificationHelper: NotificationHelper, router: Router) {
        super(notificationHelper, i18n, router);
    }

    public async activate(params: any): Promise<void> {
        this.dispatchProjectCode = params.dispatchProjectCode;
        this.requisitionId = params.requisitionId;
        this.dispatchId = params.dispatchId;
        this.workOrderId = params.workOrderId ? decodeURIComponent(params.workOrderId) : "";
        this.requisition = await materialRequisitionService.getMaterialRequisition(this.requisitionId);
        this.readonly = this.requisition.MobileStatus === "1" || !this.requisition.CreatedByCurrentUser || queryStringHelper.parseReadonly(params.q);
        this.originalRequisitionDetails = this.requisition.RequisitionDetails;
    }

    public checkDirty(): boolean {
        if (this.readonly) {
            return false;
        }

        return this.originalRequisitionDetails !== this.requisition.RequisitionDetails;
    }

    public attached(): void {
       this.resize();
    }

    public async save(): Promise<void> {
        await materialRequisitionService.setMaterialRequisitionMemo(this.requisition);
        this.originalRequisitionDetails = this.requisition.RequisitionDetails;
        routerHelper.navigateBack();
    }

    private resize(): void {
        if (this.element) {
            this.element.style.width = "100%";
            this.element.style.height = this.element.scrollHeight + this.minPaddingNoScroll + "px";
        }
    }
}
