import _ from "underscore";
import moment from "moment";

import dateHelper from "helpers/dateHelper";
import labelHelper from "helpers/labelHelper";
import routerHelper from "helpers/routerHelper";
import workorderTimesheetService from "services/workorderTimesheetService";
import { WorkOrderTimesheetResourceModel } from "api/models/company/workorder/work-order-timesheet-resource-model";
import { I18N } from "aurelia-i18n";
import { autoinject } from "aurelia-framework";
import { BonusGroup } from "api/enums/bonus-group";

@autoinject
export class FieldServiceHoursSummary {
    public list: WorkOrderTimesheetResourceModel[] = [];
    public selectedDate: Date = moment().toDate();
    public selectedMaxDate: Date = moment().toDate();
    public dateHelper: typeof dateHelper = dateHelper;
    public labelHelper: typeof labelHelper = labelHelper;
    public readonly bonusGroup: typeof BonusGroup = BonusGroup;

    constructor(public readonly i18N: I18N) {
    }
  
    public activate(): any {
        this.selectedDate = moment().toDate();
        this.selectedMaxDate = moment().toDate();
      
        return this.loadData();
    }

    public dateChanged(): void {
        this.reloadList();
    }

    private loadData(): Promise<any> {
        return workorderTimesheetService.getHoursSummary(dateHelper.dateToNavigationParameter(this.selectedDate), dateHelper.dateToNavigationParameter(this.selectedMaxDate)).then((data: WorkOrderTimesheetResourceModel[]) => {
            this.list = data;
        });
    }

    private reloadList(): void {
        routerHelper.showLoading();
        this.loadData().then(() => {
            routerHelper.hideLoading();
        });
    }
}
